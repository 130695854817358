import { TransactionSignerAccount } from '@algorandfoundation/algokit-utils/types/account'
import { TransactionSigner } from 'algosdk'
import { FEE_SINK, REGISTRY_APP_ID } from '@/data/constants'
import { algodClient } from './algod'
import { NfdRegistryClient } from './NFDRegistryClient'
import { Constraints, RawConstraints } from './types'
import { makeEmptyTransactionSigner } from './utils/makeEmptyTransactionSigner'
import { ParamsCache } from './utils/paramsCache'
import { transformRawConstraints } from './utils/transformContractData'

export async function getRegistryClient(
  signer: TransactionSigner,
  activeAddress: string
): Promise<NfdRegistryClient> {
  const params = await ParamsCache.getSuggestedParams()

  return new NfdRegistryClient(
    {
      sender: { signer, addr: activeAddress } as TransactionSignerAccount,
      resolveBy: 'id',
      id: REGISTRY_APP_ID,
      params
    },
    algodClient
  )
}

export async function getSimulateRegistryClient(
  senderAddr: string = FEE_SINK,
  authAddr?: string
): Promise<NfdRegistryClient> {
  const params = await ParamsCache.getSuggestedParams()

  return new NfdRegistryClient(
    {
      sender: { addr: senderAddr, signer: makeEmptyTransactionSigner(authAddr) },
      resolveBy: 'id',
      id: REGISTRY_APP_ID,
      params
    },
    algodClient
  )
}

export async function callGetConstraints(client: NfdRegistryClient) {
  return client
    .compose()
    .getConstraints({})
    .simulate({ allowEmptySignatures: true, allowUnnamedResources: true })
}

export async function fetchConstraints(client?: NfdRegistryClient): Promise<Constraints> {
  try {
    const registryClient = client || (await getSimulateRegistryClient())
    const result = await callGetConstraints(registryClient)
    const rawConstraints = result.returns![0] as RawConstraints
    return transformRawConstraints(rawConstraints)
  } catch (error) {
    console.error(error)
    throw error
  }
}
