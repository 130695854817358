import { RawConstraints, Constraints } from '../types'

export function transformRawConstraints(rawConstraints: RawConstraints): Constraints {
  const [
    segmentPlatformCostInUsd,
    segmentPlatformCostInAlgo,
    maxYearsAllowed,
    treasuryAddress,
    expiredAuctionDuration,
    expiredStartingPrice,
    newMintCarryCost
  ] = rawConstraints

  return {
    segmentPlatformCostInUsd,
    segmentPlatformCostInAlgo,
    maxYearsAllowed: Number(maxYearsAllowed),
    treasuryAddress,
    expiredAuctionDuration: Number(expiredAuctionDuration),
    expiredStartingPrice,
    newMintCarryCost
  }
}
