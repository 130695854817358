import * as React from 'react'
import { clsxMerge, convertMicroalgosToUsd, formatNumber } from 'helpers/utilities'
import { useNfdConstraints } from 'api/hooks/contracts/useNfdConstraints'

interface UsePriceProps {
  price: number
  className?: string
}

export default function UsdPrice({ price, className = '' }: UsePriceProps) {
  const constraintsQuery = useNfdConstraints({ staleTime: 0 })
  const platformFee = Number(constraintsQuery.data?.segmentPlatformCostInAlgo || 0)
  const platformFeeUsd = Number(constraintsQuery.data?.segmentPlatformCostInUsd || 0)

  const algoUsd = React.useMemo(() => {
    if (platformFee && platformFeeUsd) {
      return (platformFeeUsd / platformFee) * 1e4
    }
    return 0
  }, [platformFee, platformFeeUsd])

  const priceUsd = convertMicroalgosToUsd(price, algoUsd || 0, 'roundHalfUp')
  const formattedPrice = `$${formatNumber(priceUsd, { minimumFractionDigits: 2 })}`

  if (algoUsd > 0) {
    return (
      <span
        className={clsxMerge(
          'text-gray-400 text-xs font-medium whitespace-nowrap dark:text-gray-500',
          className
        )}
      >
        &#8776; {formattedPrice}
      </span>
    )
  }

  return null
}
