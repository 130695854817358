import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { captureException } from '@sentry/nextjs'
import { Constraints } from '@/lib/clients/types'
import { fetchConstraints } from '@/lib/clients/v3'

// @todo: upgrade to react-query v5 and use the new object-based API (see queries.ts)
export function useNfdConstraints(options?: UseQueryOptions<Constraints, Error>) {
  const query = useQuery<Constraints, Error>(['constraints'], () => fetchConstraints(), {
    staleTime: 1000 * 60 * 30, // 30 mins
    ...options
  })

  if (query.error) {
    captureException(query.error)
  }

  return query
}
